import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Layout, SEO, Hero, Contact, Map } from '../components';

function SuccessPage() {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Success" />
      <Hero
        image={file.childImageSharp.fluid}
        heading="Success"
        copy="Thank you, our team will get in touch shortly."
        hasCTA
        hasGradient={false}
      />
      <Contact />
      <Map />
    </Layout>
  );
}

export default SuccessPage;
